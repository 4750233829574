import { createContext, useCallback, useMemo, useState } from "react";
import ProfileService from "services/ProfileService";

const defaultContext = {
  loading: false,
  getProfile: () => {},
};

export const AppContext = createContext(defaultContext);

export const AppProvider = ({ children }) => {
  const [profile, setProfile] = useState();
  const [links, setLinks] = useState([]);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(defaultContext.loading);
  const [error, setError] = useState();
  const [showLeadForm, setShowLeadForm] = useState(false);

  const getLinks = useCallback(async (profileId) => {
    setLoading(true);
    try {
      const res = await ProfileService.getLinks(profileId);
      setLinks(res.data.results);
    } catch (err) {
      console.error(err);
    }
    setLoading(false);
  }, []);

  const getProducts = useCallback(async (profileId) => {
    setLoading(true);
    try {
      const res = await ProfileService.getProducts(profileId);
      setProducts(res.data.results);
    } catch (err) {
      console.error(err);
    }
    setLoading(false);
  }, []);

  const getProfile = useCallback(
    async (id) => {
      setLoading(true);
      try {
        const res = await ProfileService.get(id);
        if (res.data.directOn === true) {
          const link = res.data.direct;
          var urlString =
            link.type === "url"
              ? link.value
              : link.platform.webBaseURL + link.value;
          console.log(urlString);
          window.open(urlString, "_self");
        } else {
          setProfile(res.data);
          setShowLeadForm(res.data.leadCapture);
          getLinks(res.data.id);
          getProducts(res.data.id);
        }
      } catch (err) {
        console.error(err);
        window.open("/not-found", "_self");
      }
      setLoading(false);
    },
    [getLinks, getProducts]
  );

  const createLead = useCallback(async (data) => {
    setLoading(true);
    try {
      await ProfileService.createLead(data);
      setShowLeadForm(false);
    } catch (err) {
      setError(err);
    }
    setLoading(false);
  }, []);

  const contextValues = useMemo(
    () => ({
      profile,
      links,
      products,
      error,
      loading,
      showLeadForm,
      setShowLeadForm,
      getProfile,
      createLead,
    }),
    [
      profile,
      links,
      products,
      error,
      loading,
      showLeadForm,
      getProfile,
      createLead,
    ]
  );

  return (
    <AppContext.Provider value={contextValues}>{children}</AppContext.Provider>
  );
};
