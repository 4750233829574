import { ApiService } from "./ApiService";

class ProfileService extends ApiService {
  /**
   * Get a profile by their ID
   * @param {ObjectId} id - The ID of the profile to get
   * @returns {Promise<Profile>}
   */
  get(id) {
    return this.instance.get(`/v1/profiles/public/${id}`);
  }

  /**
   * Get profile links by profile ID
   * @param {ObjectId} profileId - The ID of the profile to get links
   * @returns {Promise<Profile>}
   */
  getLinks(profileId) {
    return this.instance.get(`/v1/links?profile=${profileId}&limit=100`);
  }

  /**
   * Get profile products by profile ID
   * @param {ObjectId} profileId - The ID of the profile to get products
   * @returns {Promise<Profile>}
   */
  getProducts(profileId) {
    return this.instance.get(`/v1/products?profile=${profileId}&limit=100`);
  }

  /**
   * Create lead
   * @param {object} data - The data required to create a lead
   * @returns {Promise<Lead>} A Promise that resolves to the created lead object
   */
  createLead(data) {
    return this.instance.post("/v1/leads", data);
  }
}

const profileService = new ProfileService();

export default profileService;
