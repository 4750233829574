import LeadCaptureModal from "components/profile/LeadCaptureModal";
import LinksList from "components/profile/LinksList";
import ProductsList from "components/profile/ProductsList";
import { useAppContext } from "hooks/useAppContext";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import "./profile.css";

const Profile = ({ match }) => {
  const id = match.params.id;

  // ** Hooks
  const { profile, links, products, getProfile } = useAppContext();

  useEffect(() => {
    getProfile(id);
  }, [id, getProfile]);

  const connectHandler = () => {
    var urlString =
      "https://api.uniticard.com/v1/profiles/contact-card/" + profile.id;
    window.open(urlString, "_self");
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Uniti</title>
      </Helmet>
      {profile && (
        <section>
          <div
            className="m-auto col-md-4 card rounded-0 px-0 my-0 text-center"
            style={{ maxWidth: "400px" }}
          >
            <div
              style={{
                background: profile.themeColor
                  ? `linear-gradient(${profile.themeColor},transparent)`
                  : "transparent",
              }}
            >
              <div
                className="profile-cover"
                style={{
                  backgroundImage: `url(${
                    profile.cover && profile.cover !== ""
                      ? `${process.env.REACT_APP_API_URL}/v1/${profile.cover}`
                      : `${process.env.PUBLIC_URL}/assets/cover_placeholder.png`
                  })`,
                }}
              >
                <div
                  className="profile-image rounded-circle img-fluid"
                  style={{
                    backgroundImage: `url(${
                      profile.image && profile.image !== ""
                        ? `${process.env.REACT_APP_API_URL}/v1/${profile.image}`
                        : `${process.env.PUBLIC_URL}/assets/cover_placeholder.png`
                    })`,
                  }}
                >
                  {profile.logo && profile.logo !== "" && (
                    <img
                      src={
                        profile.logo && profile.logo !== ""
                          ? `${process.env.REACT_APP_API_URL}/v1/${profile.logo}`
                          : `${process.env.PUBLIC_URL}/assets/cover_placeholder.png`
                      }
                      className="profile-logo rounded-circle img-fluid"
                      alt=""
                    />
                  )}
                </div>
              </div>

              <div className="px-4" style={{ marginTop: "50px" }}>
                <h4>{profile.name}</h4>
                <span className="text-muted d-block">{profile.location}</span>
                <span className="text-muted d-block mb-2">{profile.bio}</span>
                <button
                  onClick={connectHandler}
                  className="btn btn-primary mb-3"
                  style={{
                    backgroundColor: `${
                      (profile.themeColor &&
                        profile.themeColor !== "#FFFFFF" &&
                        profile.themeColor) ??
                      "#000000"
                    }`,
                    borderRadius: "20px",
                  }}
                >
                  Save Contact
                </button>
              </div>
            </div>

            <LinksList links={links} />
            <ProductsList products={products} category={profile.category} />
            <a
              href="https://uniticard.com"
              target="_blank"
              rel="noreferrer"
              className="btn btn-light m-3"
              style={{
                borderRadius: "20px",
              }}
            >
              Create your own profile
            </a>
          </div>

          <LeadCaptureModal profile={profile} />
        </section>
      )}
    </>
  );
};

export default Profile;
