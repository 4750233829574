import React from "react";
import ProductCard from "./ProductCard";

const ProductsList = ({ products, category }) => {
  return (
    <div>
      {products && products.length >= 1 && (
        <div className="row m-3">
          <h4>{category || "Products"}</h4>
          {products.map((product, key) => {
            return (
              <div className="col-12 card p-0 my-1" key={key}>
                <ProductCard product={product} />
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default ProductsList;
