import React from "react";
import LinkCard from "./LinkCard";

const LinksList = ({ links }) => {
  return (
    <div>
      {links && links.length >= 1 && (
        <div className="row mx-4">
          {links.map((link, key) => {
            return link.status ? (
              <div className="col-4" key={key}>
                <LinkCard link={link} />
              </div>
            ) : (
              <></>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default LinksList;
